import { Navigate, Outlet, Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

import Header from "../Components/Layout/Header";
import MainHero from "../Components/Layout/MainHero";
import MainHeroImage from "../Components/Layout/MainHeroImage";
import Canvas from "../Components/Layout/Canvas";
import LazyShow from "../Components/Layout/LazyShow";
import Product from "../Components/Layout/Product";
import About from "../Components/Layout/About";
import Pricing from "../Components/Layout/Pricing";

import { Flex, Layout } from 'antd';

export const HomeLayout = () => {
  const { access_token } = useAuth();

  if (access_token) {
    return <Navigate to="/app" />;
  }

  return (
    <div className={`bg-background grid gap-y-16 overflow-hidden`}>
      <div className={`relative bg-background`}>
        <div className="max-w-7xl mx-auto">
          <div
            className={`relative z-10 pb-8 bg-background sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}
          >
            <Header />
            <MainHero />
          </div>
        </div>
        <MainHeroImage />
      </div>
      <Canvas />
      <LazyShow>
        <>
          <Product />
          {/* <Canvas /> */}
        </>
      </LazyShow>
      <LazyShow>
        <>
        <Pricing />
          {/* <Canvas /> */}
          <About />
        </>
      </LazyShow>
    </div>
    // <Flex gap="middle" wrap>
    //   <Layout>
    //     <Header>
    //       <nav>
    //         <Link to="/">Home</Link>
    //         <Link to="/login">Login</Link>
    //       </nav>
    //     </Header>
    //     <Content>
    //       <Outlet />
    //     </Content>
    //     <Footer>Footer</Footer>
    //   </Layout>
    // </Flex>

    // <div>
    //   <nav>
    //     <Link to="/">Home</Link>
    //     <Link to="/login">Login</Link>
    //   </nav>
    //   <Outlet />
    // </div>
  )
};