import React from "react";

import { useTranslation } from 'react-i18next';

import { Button, Form, Grid, Input, theme, Typography, Alert } from "antd";

import { LockOutlined, PhoneOutlined } from "@ant-design/icons";

import { useAuth } from "../hooks/useAuth";
import { UserApi } from "../api/api";
import { Configuration } from "../api/configuration";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

export default function Login() {
  const { t } = useTranslation();
  const { login } = useAuth();
  const { token } = useToken();
  const screens = useBreakpoint();

  const [errorMessage, setErrorMessage] = React.useState<String | null>(null);


  const onFinish = async (values: any) => {
    if (!values.login || !values.password) {
      return;
    }
    var api_config = new Configuration()
    api_config.basePath = process.env.REACT_APP_API_URL
    var userAPI = new UserApi(api_config)
    userAPI.signUp({ "login": values.login, "password": values.password }).then(async (response) => {
      await login({ token: response.data.token || "" });
    }).catch((error) => {
      console.log(error);
      console.log(error.response.data);
      setErrorMessage(error.response.data.code);
      console.log("login error");
    });
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px"
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%"
    },
    forgotPassword: {
      float: "right"
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
    },
    text: {
      color: token.colorTextSecondary
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    }
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Title style={styles.title}>Sign in</Title>
          <Text style={styles.text}>
            {/* Welcome back to AntBlocks UI! Please enter your details below to
            sign in. */}
          </Text>
        </div>
        <Form
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="login"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please input your phone number!",
              },
            ]}
          >
            <Input
              prefix={<PhoneOutlined />}
              placeholder="Phone number"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          {/* <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <a style={styles.forgotPassword} href="">
              Forgot password?
            </a>
          </Form.Item> */}
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button block={true} type="primary" htmlType="submit">
              Log in
            </Button>
            {/* <div style={styles.footer}>
              <Text style={styles.text}>Don't have an account?</Text>{" "}
              <Link href="">Sign up now</Link>
            </div> */}
          </Form.Item>
        </Form>
        <br />
        {errorMessage && (
          <Alert
            message={t("login.error")}
            description={t("login.errors." + errorMessage)}
            type="error"
            showIcon
          />
        )}
      </div>
    </section>
  );
}