import { useRecoilState } from 'recoil';
import { ProfileState } from '../Atoms';

export default function Profile() {
    const [profile, setProfile] = useRecoilState(ProfileState);

    return (
        <div>
            <h1>Profile: {profile?.id} </h1>
            {!profile?.stripe_portal_link &&
                <div>
                    <a href={profile?.stripe_subscribe_link}>Subscribe</a>
                </div>
            }
            {profile?.stripe_portal_link &&
                <div>
                    <a href={profile?.stripe_portal_link}>Manage</a>
                </div>
            }
        </div>
    );
}