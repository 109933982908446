import React, { useState, useEffect } from 'react';

import { Col, Row } from 'antd';

import { useRecoilState } from 'recoil';
import { VehiclesState, NotTrackedVehiclesState } from '../Atoms';

import { useAuth } from "../hooks/useAuth";
import { VehiclesApi, Vehicle as VehicleDTO } from "../api/api";
import { Configuration } from "../api/configuration";
import Vehicle from './Vehicle';


export default function Vehicles() {
    const { access_token } = useAuth();

    const [automatedVehicles, setAutomatedVehicles] = useRecoilState(VehiclesState);
    const [vehiclesNotAutomated, setVehiclesNotAutomated] = useRecoilState(NotTrackedVehiclesState);

    // const [vehiclesNotAutomated, setVehiclesNotAutomated] = useState<any[]>([]);

    const reload = () => {
        loadVehicle();
    }

    const loadVehicle = () => {
        var api_config = new Configuration()
        api_config.accessToken = access_token.token
        api_config.basePath = process.env.REACT_APP_API_URL
        var vehiclesApi = new VehiclesApi(api_config)
        vehiclesApi.getVehicles(0, 1000).then((response) => {
            let automated = response.data.filter((vehicle: VehicleDTO) => vehicle.automated)
            setAutomatedVehicles(automated);
            let notAutomated = response.data.filter((vehicle: VehicleDTO) => !vehicle.automated)
            setVehiclesNotAutomated(notAutomated);
        }).catch((error) => {
            console.log(error)
        })
    }


    useEffect(() => {
        loadVehicle();
    }, []);

    return (
        <div>
            {automatedVehicles && automatedVehicles.length > 0 &&
                <div>
                    {automatedVehicles.map((vehicle: VehicleDTO) => (
                        <div key={vehicle.licensePlate}>
                            <Row>
                                <Col span={24}>
                                    <Vehicle reload={reload} vehicle={vehicle} />
                                </Col>
                            </Row>
                            <br />
                        </div>
                    ))}
                </div>
            }

            {vehiclesNotAutomated && vehiclesNotAutomated.length > 0 &&
                <div>
                    {vehiclesNotAutomated.map((vehicle: VehicleDTO) => (
                        // filter only vehicles which are not automated
                        !vehicle.automated &&
                        <Row key={vehicle.licensePlate}>
                            <Col span={24}>
                                <Vehicle reload={reload} vehicle={vehicle} />
                            </Col>
                        </Row>
                    ))}
                </div>
            }


        </div>
    );
}