import { useTranslation } from 'react-i18next';

import Divider from './Divider';

const Product = () => {
  const { t } = useTranslation();

  const title = t('home.product_title');

  const features = [
    { title: t("home.product.time_saving.title"), description: t("home.product.time_saving.description"), icon: "/time_management.svg" },
    { title: t("home.product.easy_to_use.title"), description: t("home.product.easy_to_use.description"), icon: "/easy.svg" },
    { title: t("home.product.secure.title"), description: t("home.product.secure.description"), icon: "/payment.svg" },
  ];

  return (
    <section className={`bg-background py-8`} id="product">
      <div className={`container max-w-5xl mx-auto m-8`}>
        <h1
          className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}
        >
          {title.split(' ').map((word, index) => (
            <span
              key={index}
              className={index % 2 ? 'text-primary' : 'text-border'}
            >
              {word}{' '}
            </span>
          ))}
        </h1>
        <Divider />
        <div className={`flex flex-wrap`}>
          <div className={`w-5/6 sm:w-1/2 p-6 mt-20`}>
            <h3
              className={`text-3xl text-gray-800 font-bold leading-none mb-3`}
            >
              {features[0].title}
            </h3>
            <p className={`text-gray-600`}>{features[0].description}</p>
          </div>
          <div className={`w-full sm:w-1/3 p-6`}>
            <img
              className="h-6/6"
              src={features[0].icon}
              alt=""
            />
          </div>
        </div>
        <div className={`flex flex-wrap flex-col-reverse sm:flex-row`}>
          <div className={`w-full sm:w-1/3 p-6`}>
            <img
              className="h-6/6"
              src={features[1].icon}
              alt=""
            />
          </div>
          <div className={`w-full sm:w-1/2 p-6 mt-20`}>
            <div className={`align-middle`}>
              <h3
                className={`text-3xl text-gray-800 font-bold leading-none mb-3`}
              >
                {features[1].title}
              </h3>
              <p className={`text-gray-600 mb-8`}>{features[1].description}</p>
            </div>
          </div>
        </div>
        <div className={`flex flex-wrap`}>
          <div className={`w-5/6 sm:w-1/2 p-6 mt-20`}>
            <h3
              className={`text-3xl text-gray-800 font-bold leading-none mb-3`}
            >
              {features[2].title}
            </h3>
            <p className={`text-gray-600`}>{features[2].description}</p>
          </div>
          <div className={`w-full sm:w-1/3 p-6`}>
            <img
              className="h-6/6"
              src={features[2].icon}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Product;