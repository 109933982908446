/* tslint:disable */
/* eslint-disable */
/**
 * PRM API
 * This is the documentation of the PRM API
 *
 * The version of the OpenAPI document: 1.0.11
 * Contact: team@tbc.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     * 
     * @type {number}
     * @memberof ApiResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiResponse
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'login': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface Pause
 */
export interface Pause {
    /**
     * 
     * @type {string}
     * @memberof Pause
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pause
     */
    'startAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pause
     */
    'endAt'?: string;
}
/**
 * 
 * @export
 * @interface PaymentRequest
 */
export interface PaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'clientSecret'?: string;
}
/**
 * 
 * @export
 * @interface Planning
 */
export interface Planning {
    /**
     * 
     * @type {string}
     * @memberof Planning
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Planning
     */
    'locationId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Planning
     */
    'on_monday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Planning
     */
    'on_tuesday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Planning
     */
    'on_wednesday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Planning
     */
    'on_thursday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Planning
     */
    'on_friday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Planning
     */
    'on_saturday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Planning
     */
    'on_sunday'?: boolean;
    /**
     * 
     * @type {Array<Pause>}
     * @memberof Planning
     */
    'pauses'?: Array<Pause>;
}
/**
 * 
 * @export
 * @interface Rate
 */
export interface Rate {
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Rate
     */
    'isPreferred'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Rate
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'licensePlate'?: string;
}
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'expireTime'?: string;
    /**
     * 
     * @type {SessionTotalCost}
     * @memberof Session
     */
    'totalCost'?: SessionTotalCost;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'locationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'parkingSessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    'is_paid'?: boolean;
    /**
     * 
     * @type {Vehicle}
     * @memberof Session
     */
    'vehicle'?: Vehicle;
}
/**
 * 
 * @export
 * @interface SessionTotalCost
 */
export interface SessionTotalCost {
    /**
     * 
     * @type {string}
     * @memberof SessionTotalCost
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionTotalCost
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'stripe_subscribe_link'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'stripe_portal_link'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'stripe_subscription_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
    /**
     * 
     * @type {boolean}
     * @memberof Vehicle
     */
    'automated': boolean;
    /**
     * 
     * @type {Array<Planning>}
     * @memberof Vehicle
     */
    'plannings'?: Array<Planning>;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'licensePlate': string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'type': string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Run daily automation
         * @param {boolean} [dryrun] If true, the automation will not be executed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDailyAutomation: async (dryrun?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/automation/daily`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "apikey", configuration)

            if (dryrun !== undefined) {
                localVarQueryParameter['dryrun'] = dryrun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run daily check automation
         * @param {boolean} [dryrun] If true, the automation will not be executed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDailyCheckAutomation: async (dryrun?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/automation/daily/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "apikey", configuration)

            if (dryrun !== undefined) {
                localVarQueryParameter['dryrun'] = dryrun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Run daily automation
         * @param {boolean} [dryrun] If true, the automation will not be executed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runDailyAutomation(dryrun?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runDailyAutomation(dryrun, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.runDailyAutomation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Run daily check automation
         * @param {boolean} [dryrun] If true, the automation will not be executed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runDailyCheckAutomation(dryrun?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runDailyCheckAutomation(dryrun, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.runDailyCheckAutomation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Run daily automation
         * @param {boolean} [dryrun] If true, the automation will not be executed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDailyAutomation(dryrun?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.runDailyAutomation(dryrun, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run daily check automation
         * @param {boolean} [dryrun] If true, the automation will not be executed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDailyCheckAutomation(dryrun?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.runDailyCheckAutomation(dryrun, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Run daily automation
     * @param {boolean} [dryrun] If true, the automation will not be executed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public runDailyAutomation(dryrun?: boolean, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).runDailyAutomation(dryrun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run daily check automation
     * @param {boolean} [dryrun] If true, the automation will not be executed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public runDailyCheckAutomation(dryrun?: boolean, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).runDailyCheckAutomation(dryrun, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Subscribe to the service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Subscribe to the service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentApi.subscribe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary Subscribe to the service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe(options?: RawAxiosRequestConfig): AxiosPromise<PaymentRequest> {
            return localVarFp.subscribe(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary Subscribe to the service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public subscribe(options?: RawAxiosRequestConfig) {
        return PaymentApiFp(this.configuration).subscribe(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SessionsApi - axios parameter creator
 * @export
 */
export const SessionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all sessions
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessions: async (offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionsApi - functional programming interface
 * @export
 */
export const SessionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all sessions
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessions(offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Session>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessions(offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SessionsApi.getSessions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SessionsApi - factory interface
 * @export
 */
export const SessionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all sessions
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessions(offset?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<Session>> {
            return localVarFp.getSessions(offset, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionsApi - object-oriented interface
 * @export
 * @class SessionsApi
 * @extends {BaseAPI}
 */
export class SessionsApi extends BaseAPI {
    /**
     * 
     * @summary Get all sessions
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public getSessions(offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return SessionsApiFp(this.configuration).getSessions(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs user with password into the system
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp: async (loginRequest?: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Logs user with password into the system
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUp(loginRequest?: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUp(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.signUp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs user with password into the system
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp(loginRequest?: LoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginResponse> {
            return localVarFp.signUp(loginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getProfile(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logs user with password into the system
     * @param {LoginRequest} [loginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public signUp(loginRequest?: LoginRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).signUp(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehiclesApi - axios parameter creator
 * @export
 */
export const VehiclesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Pause a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to pause
         * @param {string} startAt The date at which the pause starts
         * @param {string} endAt The date at which the pause ends
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPause: async (licensePlate: string, planningId: string, startAt: string, endAt: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licensePlate' is not null or undefined
            assertParamExists('addPause', 'licensePlate', licensePlate)
            // verify required parameter 'planningId' is not null or undefined
            assertParamExists('addPause', 'planningId', planningId)
            // verify required parameter 'startAt' is not null or undefined
            assertParamExists('addPause', 'startAt', startAt)
            // verify required parameter 'endAt' is not null or undefined
            assertParamExists('addPause', 'endAt', endAt)
            const localVarPath = `/vehicles/{licensePlate}/plannings/{planningId}/pauses`
                .replace(`{${"licensePlate"}}`, encodeURIComponent(String(licensePlate)))
                .replace(`{${"planningId"}}`, encodeURIComponent(String(planningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startAt !== undefined) {
                localVarQueryParameter['start_at'] = (startAt as any instanceof Date) ?
                    (startAt as any).toISOString().substring(0,10) :
                    startAt;
            }

            if (endAt !== undefined) {
                localVarQueryParameter['end_at'] = (endAt as any instanceof Date) ?
                    (endAt as any).toISOString().substring(0,10) :
                    endAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new planning to a vehicle
         * @param {string} licensePlate License plate of the vehicle
         * @param {Planning} [planning] Planning object that needs to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlanning: async (licensePlate: string, planning?: Planning, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licensePlate' is not null or undefined
            assertParamExists('addPlanning', 'licensePlate', licensePlate)
            const localVarPath = `/vehicles/{licensePlate}/plannings`
                .replace(`{${"licensePlate"}}`, encodeURIComponent(String(licensePlate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(planning, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a pause
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id of the pause
         * @param {string} pauseId Pause id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePause: async (licensePlate: string, planningId: string, pauseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licensePlate' is not null or undefined
            assertParamExists('deletePause', 'licensePlate', licensePlate)
            // verify required parameter 'planningId' is not null or undefined
            assertParamExists('deletePause', 'planningId', planningId)
            // verify required parameter 'pauseId' is not null or undefined
            assertParamExists('deletePause', 'pauseId', pauseId)
            const localVarPath = `/vehicles/{licensePlate}/plannings/{planningId}/pauses/{pauseId}`
                .replace(`{${"licensePlate"}}`, encodeURIComponent(String(licensePlate)))
                .replace(`{${"planningId"}}`, encodeURIComponent(String(planningId)))
                .replace(`{${"pauseId"}}`, encodeURIComponent(String(pauseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanning: async (licensePlate: string, planningId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licensePlate' is not null or undefined
            assertParamExists('deletePlanning', 'licensePlate', licensePlate)
            // verify required parameter 'planningId' is not null or undefined
            assertParamExists('deletePlanning', 'planningId', planningId)
            const localVarPath = `/vehicles/{licensePlate}/plannings/{planningId}`
                .replace(`{${"licensePlate"}}`, encodeURIComponent(String(licensePlate)))
                .replace(`{${"planningId"}}`, encodeURIComponent(String(planningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get rates for a location
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} locationId location id to lookup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRateForLocation: async (licensePlate: string, locationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licensePlate' is not null or undefined
            assertParamExists('getRateForLocation', 'licensePlate', licensePlate)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getRateForLocation', 'locationId', locationId)
            const localVarPath = `/vehicles/{licensePlate}/rates/{locationId}`
                .replace(`{${"licensePlate"}}`, encodeURIComponent(String(licensePlate)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all sessions for a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to lookup
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsForPlanning: async (licensePlate: string, planningId: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licensePlate' is not null or undefined
            assertParamExists('getSessionsForPlanning', 'licensePlate', licensePlate)
            // verify required parameter 'planningId' is not null or undefined
            assertParamExists('getSessionsForPlanning', 'planningId', planningId)
            const localVarPath = `/vehicles/{licensePlate}/plannings/{planningId}/sessions`
                .replace(`{${"licensePlate"}}`, encodeURIComponent(String(licensePlate)))
                .replace(`{${"planningId"}}`, encodeURIComponent(String(planningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all vehicles
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicles: async (offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to update
         * @param {Planning} [planning] Planning object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanning: async (licensePlate: string, planningId: string, planning?: Planning, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licensePlate' is not null or undefined
            assertParamExists('updatePlanning', 'licensePlate', licensePlate)
            // verify required parameter 'planningId' is not null or undefined
            assertParamExists('updatePlanning', 'planningId', planningId)
            const localVarPath = `/vehicles/{licensePlate}/plannings/{planningId}`
                .replace(`{${"licensePlate"}}`, encodeURIComponent(String(licensePlate)))
                .replace(`{${"planningId"}}`, encodeURIComponent(String(planningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(planning, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehiclesApi - functional programming interface
 * @export
 */
export const VehiclesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehiclesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Pause a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to pause
         * @param {string} startAt The date at which the pause starts
         * @param {string} endAt The date at which the pause ends
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPause(licensePlate: string, planningId: string, startAt: string, endAt: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pause>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPause(licensePlate, planningId, startAt, endAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.addPause']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add a new planning to a vehicle
         * @param {string} licensePlate License plate of the vehicle
         * @param {Planning} [planning] Planning object that needs to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPlanning(licensePlate: string, planning?: Planning, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Planning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPlanning(licensePlate, planning, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.addPlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a pause
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id of the pause
         * @param {string} pauseId Pause id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePause(licensePlate: string, planningId: string, pauseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePause(licensePlate, planningId, pauseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.deletePause']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlanning(licensePlate: string, planningId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlanning(licensePlate, planningId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.deletePlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get rates for a location
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} locationId location id to lookup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRateForLocation(licensePlate: string, locationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRateForLocation(licensePlate, locationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.getRateForLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all sessions for a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to lookup
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessionsForPlanning(licensePlate: string, planningId: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Session>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionsForPlanning(licensePlate, planningId, offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.getSessionsForPlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all vehicles
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicles(offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicles(offset, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.getVehicles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to update
         * @param {Planning} [planning] Planning object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlanning(licensePlate: string, planningId: string, planning?: Planning, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlanning(licensePlate, planningId, planning, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VehiclesApi.updatePlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VehiclesApi - factory interface
 * @export
 */
export const VehiclesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehiclesApiFp(configuration)
    return {
        /**
         * 
         * @summary Pause a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to pause
         * @param {string} startAt The date at which the pause starts
         * @param {string} endAt The date at which the pause ends
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPause(licensePlate: string, planningId: string, startAt: string, endAt: string, options?: RawAxiosRequestConfig): AxiosPromise<Pause> {
            return localVarFp.addPause(licensePlate, planningId, startAt, endAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new planning to a vehicle
         * @param {string} licensePlate License plate of the vehicle
         * @param {Planning} [planning] Planning object that needs to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlanning(licensePlate: string, planning?: Planning, options?: RawAxiosRequestConfig): AxiosPromise<Planning> {
            return localVarFp.addPlanning(licensePlate, planning, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a pause
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id of the pause
         * @param {string} pauseId Pause id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePause(licensePlate: string, planningId: string, pauseId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePause(licensePlate, planningId, pauseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlanning(licensePlate: string, planningId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deletePlanning(licensePlate, planningId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get rates for a location
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} locationId location id to lookup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRateForLocation(licensePlate: string, locationId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Rate>> {
            return localVarFp.getRateForLocation(licensePlate, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all sessions for a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to lookup
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsForPlanning(licensePlate: string, planningId: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<Session>> {
            return localVarFp.getSessionsForPlanning(licensePlate, planningId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all vehicles
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicles(offset?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<Vehicle>> {
            return localVarFp.getVehicles(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a planning
         * @param {string} licensePlate License plate of the vehicle
         * @param {string} planningId Planning id to update
         * @param {Planning} [planning] Planning object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlanning(licensePlate: string, planningId: string, planning?: Planning, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updatePlanning(licensePlate, planningId, planning, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehiclesApi - object-oriented interface
 * @export
 * @class VehiclesApi
 * @extends {BaseAPI}
 */
export class VehiclesApi extends BaseAPI {
    /**
     * 
     * @summary Pause a planning
     * @param {string} licensePlate License plate of the vehicle
     * @param {string} planningId Planning id to pause
     * @param {string} startAt The date at which the pause starts
     * @param {string} endAt The date at which the pause ends
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public addPause(licensePlate: string, planningId: string, startAt: string, endAt: string, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).addPause(licensePlate, planningId, startAt, endAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new planning to a vehicle
     * @param {string} licensePlate License plate of the vehicle
     * @param {Planning} [planning] Planning object that needs to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public addPlanning(licensePlate: string, planning?: Planning, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).addPlanning(licensePlate, planning, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a pause
     * @param {string} licensePlate License plate of the vehicle
     * @param {string} planningId Planning id of the pause
     * @param {string} pauseId Pause id to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public deletePause(licensePlate: string, planningId: string, pauseId: string, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).deletePause(licensePlate, planningId, pauseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a planning
     * @param {string} licensePlate License plate of the vehicle
     * @param {string} planningId Planning id to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public deletePlanning(licensePlate: string, planningId: string, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).deletePlanning(licensePlate, planningId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get rates for a location
     * @param {string} licensePlate License plate of the vehicle
     * @param {string} locationId location id to lookup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public getRateForLocation(licensePlate: string, locationId: string, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).getRateForLocation(licensePlate, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all sessions for a planning
     * @param {string} licensePlate License plate of the vehicle
     * @param {string} planningId Planning id to lookup
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public getSessionsForPlanning(licensePlate: string, planningId: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).getSessionsForPlanning(licensePlate, planningId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all vehicles
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public getVehicles(offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).getVehicles(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a planning
     * @param {string} licensePlate License plate of the vehicle
     * @param {string} planningId Planning id to update
     * @param {Planning} [planning] Planning object that needs to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public updatePlanning(licensePlate: string, planningId: string, planning?: Planning, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).updatePlanning(licensePlate, planningId, planning, options).then((request) => request(this.axios, this.basePath));
    }
}



